import './App.css';

function App() {
  return (
    <div className="container mx-auto m-20 w-2/3 text-slate-800">
      <header className="header mb-8 text-center font-header">
        <h1 className="text-4xl sm:text-6xl font-light tracking-wide mb-2">Nora Baltus</h1>
      </header>
      <section className="text-md sm:text-lg font-sans font-extralight tracking-wide leading-2">
        <p className="mb-4">Hello, world! I'm Nora, a software developer and overall geek from a small town in The Netherlands. 
      I started making websites when I was still a kid, during the earlier days of the web <span class="italic text-slate-500">(free GeoCities hosting and &lt;blink&gt; were my favorite toys)</span>, and have been passionate about development ever since. 
      I currently specialize in building <span class="italic text-slate-500">(slightly more sophisticated)</span> mobile and web-based applications, but I also love computer graphics, cyber security, and data management. 
      I have a Master's degree in Computer Science and Engineering from Eindhoven University of Technology, and I'm always looking for new things to learn. 
      When I'm not coding, I enjoy doing more visually creative stuff, such as making 3D artwork, taking photos of random items, and designing user interfaces. 
      This site will showcase some of my work very soon.</p>
      <p>To contact me, send an email to mail (at) nora (dot) tv.</p>
      </section>
    </div>
  );
}

export default App;
